export const images = {
    blog_place: require('../assets/images/blog_place.png'),
    imgP: require('../assets/images/img5.png'),
    imgB: require('../assets/images/img7.png'),
    imgH: require('../assets/images/homeMade.png'),
    food: require('../assets/images/placeholder.png'),
    dateIcon: require('../assets/images/calendar.png'),
    img1: require('../assets/images/img1.1.png'),
    logoImg: require('../assets/images/logo2.png'),
    logoFfk: require('../assets/images/logo3.png'),
    offline: require('../assets/images/offline1.jpg'),
    homeImg: require('../assets/images/store.svg'),
    delivery: require('../assets/images/delivery.svg'),
    coupon: require('../assets/images/coupon.png'),
    deliveryBlue: require('../assets/images/delivery-blue.svg'),
    storeBlue: require('../assets/images/store-blue.svg'),
    userIcon: require('../assets/images/user.png'),
    cartIcon: require('../assets/images/cart.png'),
    googleIcon: require('../assets/images/google.png'),
    VisaIcon: require('../assets/images/vsc.png'),
    MasterIcon: require('../assets/images/msc.png'),
    ebtIcon: require('../assets/images/credit-card.png'),
    ebtH: require('../assets/images/ebt.svg'),
    AmericanIcon: require('../assets/images/aec.png'),
    JcbIcon: require('../assets/images/jcb.png'),
    EbtIcon: require('../assets/images/ebt.svg'),
    DDIcon: require('../assets/images/ddc.png'),
    checkedIcon: require('../assets/images/checked.png'),
    checkIcon: require('../assets/images/check.svg'),
    unCheckedIcon: require('../assets/images/unchecked.png'),
    roadIcon: require('../assets/images/road-1.png'),
    playStore: require('../assets/images/btn-play.png'),
    appStore: require('../assets/images/btn-ios.png'),
    fbIcon: require('../assets/images/fb.png'),
    Img3: require('/src/assets/images/new-img3.png'),
    Img5: require('/src/assets/images/new-img5.png'),
    homeImg4: require('/src/assets/images/home-img4.png'),
    FoodImg: require('/src/assets/images/new-img2.png'),
    SliderImg: require('/src/assets/images/slider-img.png'),
    NewImg3: require('/src/assets/images/img3.png'),
    avgImg: require('/src/assets/images/avg.png'),
    NewImg8: require('/src/assets/images/new-img8.png'),
    Img2: require('/src/assets/images/img2.png'),
    NewImg7: require('/src/assets/images/new-img7.png'),
    success: require('/src/assets/images/tick.png'),
    successStar: require('/src/assets/images/success-star.svg'),
    not_found: require('/src/assets/images/not-found.png'),
    address: require('/src/assets/images/address.png'),
    card: require('/src/assets/images/card.png'),
    order_food: require('/src/assets/images/to-do-list.png'),
    list: require('/src/assets/images/list.png'),
    whoImg: require('/src/assets/images/who.jpg'),
    about: require('/src/assets/images/about.png'),
    specials: require('/src/assets/images/specials.png'),
    shopMenu: require('/src/assets/images/shop-menu.png'),
    help: require('/src/assets/images/question.png'),
    info: require('/src/assets/images/info.png'),
    chef3: require('/src/assets/images/chef-hat3.png'),
    lists: require('/src/assets/images/lists.png'),
    enter: require('/src/assets/images/enter.png'),
    catering: require('/src/assets/images/food-tray.png'),
    account: require('/src/assets/images/user.png'),
    orderList: require('/src/assets/images/shopping-bag.png'),
    logout: require('/src/assets/images/power-off.png'),
    articleImg: require('/src/assets/images/article-img.png'),
    mailBox: require('/src/assets/images/mail-box.png'),
    siGoogle: require('/src/assets/images/google-plus.svg'),
    sifacebook: require('/src/assets/images/facebook.svg'),
    siApple: require('/src/assets/images/apple.svg'),


};


export const zipCodes = [
    '73960', '75001', '75002', '75006', '75007', '75009', '75010', '75013', '75019', '75020',
    '75021', '75022', '75023', '75024', '75025', '75028', '75032', '75033', '75034', '75035',
    '75036', '75038', '75039', '75040', '75041', '75043', '75044', '75047', '75048', '75050',
    '75051', '75054', '75056', '75057', '75058', '75060', '75061', '75062', '75063', '75065',
    '75066', '75067', '75068', '75070', '75071', '75074', '75075', '75076', '75077', '75078',
    '75079', '75080', '75081', '75082', '75083', '75085', '75087', '75089', '75090', '75091',
    '75092', '75093', '75094', '75097', '75098', '75101', '75103', '75104', '75105', '75109',
    '75110', '75114', '75115', '75116', '75117', '75119', '75120', '75121', '75124', '75125',
    '75126', '75127', '75132', '75134', '75135', '75137', '75140', '75141', '75142', '75143',
    '75144', '75146', '75147', '75148', '75149', '75150', '75151', '75152', '75154', '75156',
    '75157', '75158', '75159', '75160', '75161', '75164', '75165', '75166', '75167', '75168',
    '75169', '75172', '75173', '75180', '75181', '75182', '75189', '75201', '75202', '75203',
    '75204', '75205', '75206', '75207', '75208', '75209', '75210', '75211', '75212', '75214',
    '75215', '75216', '75217', '75218', '75219', '75220', '75221', '75222', '75223', '75224',
    '75225', '75226', '75227', '75228', '75229', '75230', '75231', '75232', '75233', '75234',
    '75235', '75236', '75237', '75238', '75240', '75241', '75242', '75243', '75244', '75246',
    '75247', '75248', '75249', '75250', '75251', '75252', '75253', '75254', '75255', '75258',
    '75260', '75261', '75262', '75263', '75264', '75265', '75266', '75267', '75270', '75275',
    '75277', '75283', '75284', '75285', '75286', '75287', '75301', '75303', '75310', '75312',
    '75313', '75315', '75320', '75323', '75326', '75334', '75336', '75339', '75340', '75342',
    '75343', '75344', '75354', '75355', '75356', '75357', '75358', '75359', '75360', '75363',
    '75364', '75367', '75368', '75370', '75371', '75372', '75373', '75374', '75376', '75378',
    '75379', '75380', '75381', '75382', '75389', '75390', '75391', '75392', '75393', '75394',
    '75395', '75397', '75398', '75401', '75402', '75403', '75404', '75407', '75409', '75410',
    '75411', '75412', '75413', '75414', '75415', '75416', '75417', '75418', '75420', '75421',
    '75422', '75423', '75424', '75426', '75428', '75429', '75432', '75433', '75434', '75435',
    '75436', '75437', '75438', '75439', '75440', '75441', '75442', '75443', '75444', '75446',
    '75447', '75448', '75449', '75450', '75451', '75452', '75453', '75454', '75455', '75456',
    '75457', '75458', '75459', '75460', '75461', '75462', '75468', '75469', '75470', '75471',
    '75472', '75473', '75474', '75475', '75476', '75477', '75478', '75479', '75480', '75481',
    '75482', '75483', '75485', '75486', '75487', '75488', '75489', '75490', '75491', '75492',
    '75493', '75494', '75495', '75496', '75497', '75501', '75503', '75504', '75505', '75507',
    '75550', '75551', '75553', '75554', '75555', '75556', '75558', '75559', '75560', '75561',
    '75563', '75564', '75565', '75566', '75567', '75568', '75569', '75570', '75571', '75572',
    '75573', '75574', '75601', '75602', '75603', '75604', '75605', '75606', '75607', '75608',
    '75615', '75630', '75631', '75633', '75636', '75637', '75638', '75639', '75640', '75641',
    '75642', '75643', '75644', '75645', '75647', '75650', '75651', '75652', '75653', '75654',
    '75656', '75657', '75658', '75659', '75660', '75661', '75662', '75663', '75666', '75667',
    '75668', '75669', '75670', '75671', '75672', '75681', '75682', '75683', '75684', '75685',
    '75686', '75687', '75688', '75689', '75691', '75692', '75693', '75701', '75702', '75703',
    '75704', '75705', '75706', '75707', '75708', '75709', '75710', '75711', '75712', '75713',
    '75750', '75751', '75752', '75754', '75755', '75756', '75757', '75758', '75760', '75762',
    '75763', '75764', '75765', '75766', '75770', '75771', '75773', '75778', '75779', '75780',
    '75782', '75783', '75785', '75788', '75789', '75790', '75791', '75792', '75798', '75799',
    '75801', '75802', '75803', '75831', '75832', '75833', '75834', '75835', '75838', '75839',
    '75840', '75844', '75845', '75846', '75847', '75848', '75849', '75850', '75851', '75852',
    '75853', '75855', '75856', '75858', '75859', '75860', '75861', '75862', '75901', '75902',
    '75903', '75904', '75915', '75925', '75926', '75928', '75929', '75930', '75931', '75932',
    '75933', '75934', '75935', '75936', '75937', '75938', '75939', '75941', '75942', '75943',
    '75944', '75946', '75948', '75949', '75951', '75954', '75956', '75958', '75959', '75960',
    '75961', '75962', '75963', '75964', '75965', '75966', '75968', '75969', '75972', '75973',
    '75974', '75975', '75976', '75977', '75978', '75979', '75980', '76001', '76002', '76003',
    '76004', '76005', '76006', '76007', '76008', '76009', '76010', '76011', '76012', '76013',
    '76014', '76015', '76016', '76017', '76018', '76019', '76020', '76021', '76022', '76023',
    '76028', '76031', '76033', '76034', '76035', '76036', '76039', '76040', '76041', '76043',
    '76044', '76048', '76049', '76050', '76051', '76052', '76053', '76054', '76055', '76058',
    '76059', '76060', '76061', '76063', '76064', '76065', '76066', '76067', '76068', '76070',
    '76071', '76073', '76077', '76078', '76082', '76084', '76085', '76086', '76087', '76088',
    '76092', '76093', '76094', '76095', '76096', '76097', '76099', '76101', '76102', '76103',
    '76104', '76105', '76106', '76107', '76108', '76109', '76110', '76111', '76112', '76113',
    '76114', '76115', '76116', '76117', '76118', '76119', '76120', '76121', '76122', '76123',
    '76124', '76126', '76127', '76129', '76130', '76131', '76132', '76133', '76134', '76135',
    '76136', '76137', '76140', '76148', '76150', '76155', '76161', '76162', '76163', '76164',
    '76166', '76177', '76179', '76180', '76181', '76182', '76185', '76191', '76192', '76193',
    '76195', '76196', '76197', '76198', '76199', '76201', '76202', '76203', '76204', '76205',
    '76206', '76207', '76208', '76209', '76210', '76225', '76226', '76227', '76228', '76230',
    '76233', '76234', '76238', '76239', '76240', '76241', '76244', '76245', '76246', '76247',
    '76248', '76249', '76250', '76251', '76252', '76253', '76255', '76258', '76259', '76261',
    '76262', '76263', '76264', '76265', '76266', '76267', '76268', '76270', '76271', '76272',
    '76273', '76301', '76302', '76305', '76306', '76307', '76308', '76309', '76310', '76311',
    '76351', '76352', '76354', '76357', '76360', '76363', '76364', '76365', '76366', '76367',
    '76370', '76371', '76372', '76373', '76374', '76377', '76379', '76380', '76384', '76385',
    '76388', '76389', '76401', '76402', '76424', '76426', '76427', '76429', '76430', '76431',
    '76432', '76433', '76435', '76436', '76437', '76439', '76442', '76443', '76444', '76445',
    '76446', '76448', '76449', '76450', '76452', '76453', '76454', '76455', '76457', '76458',
    '76459', '76460', '76461', '76462', '76463', '76464', '76465', '76466', '76467', '76468',
    '76469', '76470', '76471', '76472', '76474', '76475', '76476', '76481', '76483', '76484',
    '76485', '76486', '76487', '76490', '76491', '76501', '76502', '76503', '76504', '76505',
    '76508', '76511', '76513', '76518', '76519', '76520', '76522', '76523', '76524', '76525',
    '76526', '76527', '76528', '76530', '76531', '76533', '76534', '76537', '76538', '76539',
    '76540', '76541', '76542', '76543', '76544', '76548', '76549', '76550', '76554', '76556',
    '76557', '76558', '76559', '76561', '76564', '76565', '76566', '76567', '76569', '76570',
    '76571', '76573', '76574', '76577', '76578', '76579', '76596', '76597', '76598', '76599',
    '76621', '76622', '76623', '76624', '76626', '76627', '76628', '76629', '76630', '76631',
    '76632', '76633', '76634', '76635', '76636', '76637', '76638', '76639', '76640', '76641',
    '76642', '76643', '76644', '76645', '76648', '76649', '76650', '76651', '76652', '76653',
    '76654', '76655', '76656', '76657', '76660', '76661', '76664', '76665', '76666', '76667',
    '76670', '76671', '76673', '76676', '76678', '76679', '76680', '76681', '76682', '76684',
    '76685', '76686', '76687', '76689', '76690', '76691', '76692', '76693', '76701', '76702',
    '76703', '76704', '76705', '76706', '76707', '76708', '76710', '76711', '76712', '76714',
    '76715', '76716', '76795', '76797', '76798', '76799', '76801', '76802', '76803', '76804',
    '76820', '76821', '76823', '76824', '76825', '76827', '76828', '76831', '76832', '76834',
    '76836', '76837', '76841', '76842', '76844', '76845', '76848', '76849', '76852', '76853',
    '76854', '76856', '76857', '76858', '76859', '76861', '76862', '76864', '76865', '76866',
    '76869', '76870', '76871', '76872', '76873', '76874', '76875', '76877', '76878', '76880',
    '76882', '76883', '76884', '76885', '76886', '76887', '76888', '76889', '76890', '76901',
    '76902', '76903', '76904', '76905', '76906', '76908', '76909', '76930', '76932', '76933',
    '76934', '76935', '76936', '76937', '76939', '76940', '76941', '76943', '76945', '76949',
    '76950', '76951', '76953', '76955', '76957', '76958', '77001', '77002', '77003', '77004',
    '77005', '77006', '77007', '77008', '77009', '77010', '77011', '77012', '77013', '77014',
    '77015', '77016', '77017', '77018', '77019', '77020', '77021', '77022', '77023', '77024',
    '77025', '77026', '77027', '77028', '77029', '77030', '77031', '77032', '77033', '77034',
    '77035', '77036', '77037', '77038', '77039', '77040', '77041', '77042', '77043', '77044',
    '77045', '77046', '77047', '77048', '77049', '77050', '77051', '77052', '77053', '77054',
    '77055', '77056', '77057', '77058', '77059', '77060', '77061', '77062', '77063', '77064',
    '77065', '77066', '77067', '77068', '77069', '77070', '77071', '77072', '77073', '77074',
    '77075', '77076', '77077', '77078', '77079', '77080', '77081', '77082', '77083', '77084',
    '77085', '77086', '77087', '77088', '77089', '77090', '77091', '77092', '77093', '77094',
    '77095', '77096', '77097', '77098', '77099', '77101', '77102', '77103', '77104', '77105',
    '77106', '77107', '77108', '77109', '77110', '77111', '77112', '77113', '77114', '77115',
    '77116', '77117', '77118', '77119', '77120', '77121', '77122', '77123', '77124', '77125',
    '77126', '77127', '77128', '77129', '77130', '77131', '77132', '77133', '77134', '77135',
    '77136', '77137', '77138', '77140', '77141', '77142', '77143', '77144', '77145', '77146',
    '77147', '77148', '77149', '77150', '77151', '77152', '77153', '77154', '77155', '77156',
    '77157', '77158', '77159', '77160', '77161', '77162', '77163', '77164', '77165', '77166',
    '77167', '77168', '77169', '77170', '77171', '77172', '77173', '77174', '77175', '77176',
    '77177', '77178', '77179', '77180', '77181', '77182', '77183', '77184', '77185', '77186',
    '77187', '77188', '77189', '77190', '77201', '77202', '77203', '77204', '77205', '77206',
    '77207', '77208', '77209', '77210', '77212', '77213', '77215', '77216', '77217', '77218',
    '77219', '77220', '77221', '77222', '77223', '77224', '77225', '77226', '77227', '77228',
    '77229', '77230', '77231', '77233', '77234', '77235', '77236', '77237', '77238', '77240',
    '77241', '77242', '77243', '77244', '77245', '77246', '77247', '77248', '77249', '77250',
    '77251', '77252', '77253', '77254', '77255', '77256', '77257', '77258', '77259', '77260',
    '77261', '77262', '77263', '77265', '77266', '77267', '77268', '77269', '77270', '77271',
    '77272', '77273', '77274', '77275', '77276', '77277', '77278', '77279', '77280', '77282',
    '77284', '77285', '77286', '77287', '77288', '77289', '77290', '77291', '77292', '77293',
    '77294', '77296', '77297', '77299', '77301', '77302', '77303', '77304', '77305', '77306',
    '77315', '77316', '77318', '77320', '77325', '77326', '77327', '77328', '77331', '77332',
    '77333', '77334', '77335', '77336', '77337', '77338', '77339', '77340', '77341', '77342',
    '77343', '77344', '77345', '77346', '77347', '77348', '77349', '77350', '77351', '77353',
    '77354', '77355', '77356', '77357', '77358', '77359', '77360', '77362', '77363', '77364',
    '77365', '77367', '77368', '77369', '77371', '77372', '77373', '77374', '77375', '77376',
    '77377', '77378', '77379', '77380', '77381', '77382', '77383', '77384', '77385', '77386',
    '77387', '77388', '77389', '77391', '77393', '77396', '77399', '77401', '77402', '77404',
    '77406', '77407', '77410', '77411', '77412', '77413', '77414', '77415', '77417', '77418',
    '77419', '77420', '77422', '77423', '77426', '77427', '77428', '77429', '77430', '77431',
    '77432', '77433', '77434', '77435', '77436', '77437', '77440', '77441', '77442', '77443',
    '77444', '77445', '77446', '77447', '77448', '77449', '77450', '77451', '77452', '77453',
    '77454', '77455', '77456', '77457', '77458', '77459', '77460', '77461', '77463', '77464',
    '77465', '77466', '77467', '77468', '77469', '77470', '77471', '77473', '77474', '77475',
    '77476', '77477', '77478', '77479', '77480', '77481', '77482', '77483', '77484', '77485',
    '77486', '77487', '77488', '77489', '77491', '77492', '77493', '77494', '77495', '77496',
    '77497', '77498', '77501', '77502', '77503', '77504', '77505', '77506', '77507', '77508',
    '77510', '77511', '77512', '77514', '77515', '77516', '77517', '77518', '77519', '77520',
    '77521', '77522', '77523', '77530', '77531', '77532', '77533', '77534', '77535', '77536',
    '77538', '77539', '77541', '77542', '77545', '77546', '77547', '77549', '77550', '77551',
    '77552', '77553', '77554', '77555', '77560', '77561', '77562', '77563', '77564', '77565',
    '77566', '77568', '77571', '77572', '77573', '77574', '77575', '77577', '77578', '77580',
    '77581', '77582', '77583', '77584', '77585', '77586', '77587', '77588', '77590', '77591',
    '77592', '77597', '77598', '77611', '77612', '77613', '77614', '77615', '77616', '77617',
    '77619', '77622', '77623', '77624', '77625', '77626', '77627', '77629', '77630', '77631',
    '77632', '77639', '77640', '77641', '77642', '77650', '77651', '77655', '77656', '77657',
    '77659', '77660', '77661', '77662', '77663', '77664', '77665', '77670', '77701', '77702',
    '77703', '77704', '77705', '77706', '77707', '77708', '77709', '77710', '77713', '77720',
    '77725', '77726', '77801', '77802', '77803', '77805', '77806', '77807', '77808', '77830',
    '77831', '77833', '77834', '77835', '77836', '77837', '77838', '77839', '77840', '77841',
    '77842', '77843', '77844', '77845', '77850', '77852', '77853', '77855', '77856', '77857',
    '77859', '77861', '77862', '77863', '77864', '77865', '77866', '77867', '77868', '77869',
    '77870', '77871', '77872', '77873', '77875', '77876', '77878', '77879', '77880', '77881',
    '77882', '77901', '77902', '77903', '77904', '77905', '77950', '77951', '77954', '77957',
    '77960', '77961', '77962', '77963', '77964', '77967', '77968', '77969', '77970', '77971',
    '77973', '77974', '77975', '77976', '77977', '77978', '77979', '77982', '77983', '77984',
    '77986', '77987', '77988', '77989', '77990', '77991', '77993', '77994', '77995', '77997',
    '77998', '77999', '78001', '78002', '78003', '78004', '78005', '78006', '78007', '78008',
    '78009', '78010', '78011', '78012', '78013', '78014', '78015', '78016', '78017', '78019',
    '78021', '78022', '78023', '78024', '78025', '78026', '78027', '78028', '78029', '78039',
    '78040', '78041', '78042', '78043', '78044', '78045', '78046', '78049', '78050', '78052',
    '78053', '78054', '78055', '78056', '78057', '78058', '78059', '78060', '78061', '78062',
    '78063', '78064', '78065', '78066', '78067', '78069', '78070', '78071', '78072', '78073',
    '78074', '78075', '78076', '78101', '78102', '78104', '78107', '78108', '78109', '78111',
    '78112', '78113', '78114', '78115', '78116', '78117', '78118', '78119', '78121', '78122',
    '78123', '78124', '78125', '78130', '78131', '78132', '78133', '78135', '78140', '78141',
    '78142', '78143', '78144', '78145', '78146', '78147', '78148', '78150', '78151', '78152',
    '78154', '78155', '78156', '78159', '78160', '78161', '78162', '78163', '78164', '78201',
    '78202', '78203', '78204', '78205', '78206', '78207', '78208', '78209', '78210', '78211',
    '78212', '78213', '78214', '78215', '78216', '78217', '78218', '78219', '78220', '78221',
    '78222', '78223', '78224', '78225', '78226', '78227', '78228', '78229', '78230', '78231',
    '78232', '78233', '78234', '78235', '78236', '78237', '78238', '78239', '78240', '78241',
    '78242', '78243', '78244', '78245', '78246', '78247', '78248', '78249', '78250', '78251',
    '78252', '78253', '78254', '78255', '78256', '78257', '78258', '78259', '78260', '78261',
    '78262', '78263', '78264', '78265', '78266', '78268', '78269', '78270', '78275', '78278',
    '78279', '78280', '78283', '78284', '78285', '78286', '78287', '78288', '78289', '78291',
    '78292', '78293', '78294', '78295', '78296', '78297', '78298', '78299', '78330', '78332',
    '78333', '78335', '78336', '78338', '78339', '78340', '78341', '78342', '78343', '78344',
    '78347', '78349', '78350', '78351', '78352', '78353', '78355', '78357', '78358', '78359',
    '78360', '78361', '78362', '78363', '78364', '78368', '78369', '78370', '78371', '78372',
    '78373', '78374', '78375', '78376', '78377', '78379', '78380', '78381', '78382', '78383',
    '78384', '78385', '78387', '78389', '78390', '78391', '78393', '78401', '78402', '78403',
    '78404', '78405', '78406', '78407', '78408', '78409', '78410', '78411', '78412', '78413',
    '78414', '78415', '78416', '78417', '78418', '78419', '78426', '78427', '78460', '78461',
    '78463', '78465', '78466', '78467', '78468', '78469', '78470', '78471', '78472', '78473',
    '78474', '78475', '78476', '78477', '78478', '78480', '78501', '78502', '78503', '78504',
    '78505', '78516', '78520', '78521', '78522', '78523', '78526', '78535', '78536', '78537',
    '78538', '78539', '78540', '78541', '78542', '78543', '78545', '78547', '78548', '78549',
    '78550', '78551', '78552', '78553', '78557', '78558', '78559', '78560', '78561', '78562',
    '78563', '78564', '78565', '78566', '78567', '78568', '78569', '78570', '78572', '78573',
    '78574', '78575', '78576', '78577', '78578', '78579', '78580', '78582', '78583', '78584',
    '78585', '78586', '78588', '78589', '78590', '78591', '78592', '78593', '78594', '78595',
    '78596', '78597', '78598', '78599', '78602', '78604', '78605', '78606', '78607', '78608',
    '78609', '78610', '78611', '78612', '78613', '78614', '78615', '78616', '78617', '78618',
    '78619', '78620', '78621', '78622', '78623', '78624', '78626', '78627', '78628', '78629',
    '78630', '78631', '78632', '78633', '78634', '78635', '78636', '78638', '78639', '78640',
    '78641', '78642', '78643', '78644', '78645', '78646', '78648', '78650', '78651', '78652',
    '78653', '78654', '78655', '78656', '78657', '78658', '78659', '78660', '78661', '78662',
    '78663', '78664', '78665', '78666', '78667', '78669', '78670', '78671', '78672', '78673',
    '78674', '78675', '78676', '78677', '78680', '78681', '78682', '78683', '78691', '78701',
    '78702', '78703', '78704', '78705', '78708', '78709', '78710', '78711', '78712', '78713',
    '78714', '78715', '78716', '78717', '78718', '78719', '78720', '78721', '78722', '78723',
    '78724', '78725', '78726', '78727', '78728', '78729', '78730', '78731', '78732', '78733',
    '78734', '78735', '78736', '78737', '78738', '78739', '78741', '78742', '78744', '78745',
    '78746', '78747', '78748', '78749', '78750', '78751', '78752', '78753', '78754', '78755',
    '78756', '78757', '78758', '78759', '78760', '78761', '78762', '78763', '78764', '78765',
    '78766', '78767', '78768', '78769', '78772', '78773', '78774', '78778', '78779', '78780',
    '78781', '78782', '78783', '78785', '78786', '78787', '78788', '78789', '78798', '78799',
    '78801', '78802', '78827', '78828', '78829', '78830', '78832', '78833', '78834', '78836',
    '78837', '78838', '78839', '78840', '78841', '78842', '78843', '78847', '78850', '78851',
    '78852', '78853', '78860', '78861', '78870', '78871', '78872', '78873', '78877', '78879',
    '78880', '78881', '78883', '78884', '78885', '78886', '78931', '78932', '78933', '78934',
    '78935', '78938', '78940', '78941', '78942', '78943', '78944', '78945', '78946', '78947',
    '78948', '78949', '78950', '78951', '78952', '78953', '78954', '78956', '78957', '78959',
    '78960', '78961', '78962', '78963', '79001', '79002', '79003', '79005', '79007', '79008',
    '79009', '79010', '79011', '79012', '79013', '79014', '79015', '79016', '79018', '79019',
    '79021', '79022', '79024', '79025', '79027', '79029', '79031', '79032', '79033', '79034',
    '79035', '79036', '79039', '79040', '79041', '79042', '79043', '79044', '79045', '79046',
    '79051', '79052', '79053', '79054', '79056', '79057', '79058', '79059', '79061', '79062',
    '79063', '79064', '79065', '79066', '79068', '79070', '79072', '79073', '79077', '79078',
    '79079', '79080', '79081', '79082', '79083', '79084', '79085', '79086', '79087', '79088',
    '79091', '79092', '79093', '79094', '79095', '79096', '79097', '79098', '79101', '79102',
    '79103', '79104', '79105', '79106', '79107', '79108', '79109', '79110', '79111', '79114',
    '79116', '79117', '79118', '79119', '79120', '79121', '79124', '79159', '79160', '79161',
    '79163', '79165', '79166', '79167', '79168', '79171', '79172', '79174', '79178', '79180',
    '79181', '79182', '79184', '79185', '79186', '79187', '79189', '79201', '79220', '79221',
    '79222', '79223', '79224', '79225', '79226', '79227', '79229', '79230', '79231', '79232',
    '79233', '79234', '79235', '79236', '79237', '79239', '79240', '79241', '79243', '79244',
    '79245', '79247', '79248', '79250', '79251', '79252', '79255', '79256', '79257', '79258',
    '79259', '79261', '79311', '79312', '79313', '79314', '79316', '79322', '79323', '79324',
    '79325', '79326', '79329', '79330', '79331', '79336', '79338', '79339', '79342', '79343',
    '79344', '79345', '79346', '79347', '79350', '79351', '79353', '79355', '79356', '79357',
    '79358', '79359', '79360', '79363', '79364', '79366', '79367', '79369', '79370', '79371',
    '79372', '79373', '79376', '79377', '79378', '79379', '79380', '79381', '79382', '79401',
    '79402', '79403', '79404', '79406', '79407', '79408', '79409', '79410', '79411', '79412',
    '79413', '79414', '79415', '79416', '79423', '79424', '79430', '79452', '79453', '79457',
    '79464', '79490', '79491', '79493', '79499', '79501', '79502', '79503', '79504', '79505',
    '79506', '79508', '79510', '79511', '79512', '79516', '79517', '79518', '79519', '79520',
    '79521', '79525', '79526', '79527', '79528', '79529', '79530', '79532', '79533', '79534',
    '79535', '79536', '79537', '79538', '79539', '79540', '79541', '79542', '79543', '79544',
    '79545', '79546', '79547', '79548', '79549', '79550', '79553', '79556', '79560', '79561',
    '79562', '79563', '79565', '79566', '79567', '79601', '79602', '79603', '79604', '79605',
    '79606', '79607', '79608', '79697', '79698', '79699', '79701', '79702', '79703', '79704',
    '79705', '79706', '79707', '79708', '79709', '79710', '79711', '79712', '79713', '79714',
    '79718', '79719', '79720', '79721', '79730', '79731', '79733', '79734', '79735', '79738',
    '79739', '79740', '79741', '79742', '79743', '79744', '79745', '79748', '79749', '79752',
    '79754', '79755', '79756', '79758', '79759', '79760', '79761', '79762', '79763', '79764',
    '79765', '79766', '79768', '79769', '79770', '79772', '79776', '79777', '79778', '79780',
    '79781', '79782', '79783', '79785', '79786', '79787', '79788', '79789', '79821', '79830',
    '79831', '79832', '79834', '79835', '79836', '79837', '79838', '79839', '79842', '79843',
    '79845', '79846', '79847', '79848', '79849', '79851', '79852', '79853', '79854', '79855',
    '79901', '79902', '79903', '79904', '79905', '79906', '79907', '79908', '79910', '79911',
    '79912', '79913', '79914', '79915', '79916', '79917', '79918', '79920', '79922', '79923',
    '79924', '79925', '79926', '79927', '79928', '79929', '79930', '79931', '79932', '79934',
    '79935', '79936', '79937', '79938', '79940', '79941', '79942', '79943', '79944', '79945',
    '79946', '79947', '79948', '79949', '79950', '79951', '79952', '79953', '79954', '79955',
    '79958', '79960', '79961', '79968', '79976', '79978', '79980', '79990', '79995', '79996',
    '79997', '79998','12345'];


export const houstonZipcodes = [
    '77002', '77021', '77040', '77059', '77078', '77098', '77384', '77479', '77547', '77003', '77022', '77041',
    '77060', '77079', '77099', '77385', '77484', '77571', '77004', '77023', '77042', '77061', '77080', '77204',
    '77386', '77489', '77573', '77005', '77024', '77043', '77062', '77081', '77325', '77388', '77492', '77574',
    '77006', '77025', '77044', '77063', '77082', '77336', '77389', '77493', '77578', '77007', '77026', '77045',
    '77064', '77083', '77338', '77396', '77494', '77581', '77008', '77027', '77046', '77065', '77084', '77339',
    '77401', '77497', '77584', '77009', '77028', '77047', '77066', '77085', '77345', '77406', '77498', '77586',
    '77010', '77029', '77048', '77067', '77086', '77346', '77407', '77502', '77587', '77011', '77030', '77049',
    '77068', '77087', '77354', '77429', '77503', '77588', '77012', '77031', '77050', '77069', '77088', '77357',
    '77433', '77504', '77598', '77013', '77032', '77051', '77070', '77089', '77365', '77447', '77505', '77014',
    '77033', '77052', '77071', '77090', '77373', '77449', '77506', '77015', '77034', '77053', '77072', '77091',
    '77375', '77450', '77507', '77016', '77035', '77054', '77073', '77092', '77377', '77459', '77520', '77017',
    '77036', '77055', '77074', '77093', '77379', '77469', '77530', '77018', '77037', '77056', '77075', '77094',
    '77380', '77472', '77532', '77019', '77038', '77057', '77076', '77095', '77381', '77477', '77536', '77020',
    '77039', '77058', '77077', '77096', '77382', '77478', '77546', '12345'
];
