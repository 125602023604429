import React from 'react';

const AddressCardSkeleton = () => {
    return (
        <div>
            {Array(3).fill().map((_, index) => (
                <div
                    key={index}
                    style={{
                        backgroundColor: 'rgb(255, 255, 255)',
                        border: '1px solid rgb(204, 204, 204)',
                        transition: 'background-color 0.3s, border 0.3s',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        padding: '10px', // Reduced padding
                        borderRadius: '5px',
                        marginBottom: '8px', // Reduced margin
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                        {/* Skeleton for SVG Icon */}
                        <div
                            style={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: 'rgb(230, 230, 230)',
                                borderRadius: '3px',
                                marginBottom: '10px',
                            }}
                        ></div>
                        {/* Skeleton for Title */}
                        <div
                            style={{
                                width: '60%',
                                height: '12px',
                                backgroundColor: 'rgb(230, 230, 230)',
                                borderRadius: '3px',
                            }}
                        ></div>
                        {/* Skeleton for Small Text */}
                        <div
                            style={{
                                width: '80%',
                                height: '10px',
                                backgroundColor: 'rgb(230, 230, 230)',
                                borderRadius: '3px',
                            }}
                        ></div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AddressCardSkeleton;
